/* You can add global styles to this file, and also import other style files */

.danger {
  color: red;
}

.warning {
  color: orange;
}

.hand {
  cursor: pointer;
}
.title-area, .title-area h4 {
  margin: 0;
  padding: 0;
}

.bold {
  font-weight: bold;
}

.right {
  text-align: right;
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.pSize {
  font-size: 12px;
}

.hidden {
  display: none;
}

.fixedPosition {
  position: fixed;
}

.btntri {
  border: 1px transparent solid;
}

body {
  background-color: #FAFAFA;
  /* Couleur de fond de tout */
}

.scrollable {
  max-height: 90vh;
  overflow-y: auto;
}

.sidenav {
  width: 200px;
  height: auto;
}

.warningIcon {
  width: 15px;
  height: 15px;
}

/* ============================================================ style pour les tables =======================================  */
.table-container{
  margin-top: 25px;
  overflow: auto;
}
.table{
  width: 100%;
  margin: 0;
}
.table th{
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: lightgray;
}

.table tr{
    transition: 0.2s;
    background-color: rgb(248, 248, 248);
}
.table th, .table tr{
  padding-left: 10;
  padding-right: 10;
}

.table tr:hover{
    background-color: rgb(231, 231, 231);
}
.table td{
  vertical-align: middle;
}

.no-data {
  width: 100%;
  text-align: center;
  padding:10px;
  font-weight: bold;
}

.active-line:hover {
  background-color: rgba(160, 160, 160, 0.573) !important;
}

.active-line {
  background-color: rgba(160, 160, 160, 0.462) !important;
  color: black
}

.sorted {
  transition: 0.4s;
  border-radius: 10px;
}

.sorted:hover {
  border: 0.1px solid gray;
  cursor: pointer;
}

.desc {
  transform: rotate(180deg);
}

.not-sorted {
  opacity: 0.4;
  transition: 0.4s;
}

.not-sorted:hover {
  opacity: 1;
}

/* ============================================================ style pour le spinner =======================================  */

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  visibility: hidden;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  -moz-animation: spinner 1500ms infinite linear;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 1em;
  -webkit-box-shadow: rgb(0, 153, 255) 1.5em 0 0 0, rgb(0, 153, 255) 1.1em 1.1em 0 0, rgb(0, 153, 255) 0 1.5em 0 0, rgb(0, 153, 255) -1.1em 1.1em 0 0, rgb(0, 153, 255) -1.5em 0 0 0, rgb(0, 153, 255) -1.1em -1.1em 0 0, rgb(0, 153, 255) 0 -1.5em 0 0, rgb(0, 153, 255) 1.1em -1.1em 0 0;
  box-shadow: rgb(0, 153, 255) 1.5em 0 0 0, rgb(0, 153, 255) 1.1em 1.1em 0 0, rgb(0, 153, 255) 0 1.5em 0 0, rgb(0, 153, 255) -1.1em 1.1em 0 0, rgb(0, 153, 255) -1.5em 0 0 0, rgb(0, 153, 255) -1.1em -1.1em 0 0, rgb(0, 153, 255) 0 -1.5em 0 0, rgb(0, 153, 255) 1.1em -1.1em 0 0;
}

/* Enlever les borders des onglets dans single-instance */
* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
  -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
  outline: none !important;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}